import { ReactNode, useState } from 'react';

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    Typography,
    Button,
    useMediaQuery,
    Stack,
} from '@mui/material';

import { CloseRounded } from '@mui/icons-material';

/**
 * Modal Component
 *
 * This component is a customizable dialog/modal used for displaying content, actions, or forms in a modal window.
 * It supports dynamic titles, body content, footers, and conditional rendering of tabs.
 *
 * Props:
 * @param {Boolean} show - Flag to control the visibility of the modal.
 * @param {String} size - The maximum width of the modal ('xs', 'sm', 'md', 'lg', 'xl'). Default is 'sm'.
 * @param {String} title - Title text to be displayed at the top of the modal.
 * @param {Function} [handleClose=null] - Display a close button at the top right of the modal if passed. Default is null.
 * @param {Function} [fullWidth=false] - Flag to set the modal to full width. Default is false.
 * @param {ReactNode} modalBody - The main content of the modal, which will be rendered in the body.
 * @param {ReactNode} [customFooter] - Optional custom footer content. If provided, it will override the default footer buttons.
 * @param {Array} [footerButtons] - An array of button objects to display in the footer. Each button should have the following structure:
 *        { title: 'Button tooltip', icon: <IconComponent />, onClick: handleClick }
 *        If customFooter is provided, this prop is ignored.
 * @param {Object} [tabs] - Optional prop to pass tab names and content.
 *        - `tabs`: Array of tab names or objects with `label` and `content` keys.
 * @param {Object} [sxBody=null] - For body styling.
 * @param {Object} [sxAction] - For action buttons styling.
 * @param {Object} rest - Additional props passed to the Dialog component (e.g., `aria-labelledby`, `className`).
 *
 * Example usage:
 * <Modal
 *    show={isOpen}
 *    size="md"
 *    title="Modal Title"
 *    modalBody={<Box>Modal Content Here</Box>}
 *    footerButtons={[
 *        { title: 'Save', icon: <SaveRounded />, onClick: handleSave },
 *        { title: 'Cancel', icon: <CancelRounded />, onClick: handleCancel }
 *    ]} // Icon Buttons
 *    footerButtons={[
 *        { label: 'Save', onClick: handleSave },
 *        { label: 'Cancel', onClick: handleCancel }
 *    ]} // Text Buttons
 *    tabs={[{ label: "Tab 1", content: "Tab 1 Content" }, { label: "Tab 2", content: "Tab 2 Content" }]}
 * />
 */

const Modal = (props) => {
    const {
        show,
        size = 'sm',
        title,
        handleClose = null,
        fullWidth = true,
        modalBody,
        sxBody = null,
        sxAction,
        customFooter,
        footerButtons = [],
        modalLogo,
        tabs, // New prop to conditionally add tabs
        ...rest
    } = props;

    const [selectedTab, setSelectedTab] = useState(0); // State to manage the selected tab index
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm'),
    );

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const hasIconButtons = () =>
        Array.isArray(footerButtons) &&
        footerButtons.some((button) => button.icon);

    const renderFooterButtons = () => {
        if (!Array.isArray(footerButtons) || footerButtons.length === 0) {
            return null;
        }

        const buttons =
            typeof footerButtons === 'function'
                ? footerButtons()
                : footerButtons;

        return (
            <DialogActions
                sx={{
                    justifyContent: hasIconButtons()
                        ? 'space-around'
                        : 'center',
                    flexWrap: hasIconButtons() ? 'nowrap' : 'wrap',
                    gap: hasIconButtons() ? 0 : 1,
                    m: hasIconButtons() ? 2 : 1.5,
                    border: hasIconButtons()
                        ? (theme) => `1px solid ${theme.palette.grey.dark}`
                        : 'none',
                    borderRadius: hasIconButtons() ? 1 : 0,
                    backgroundColor: hasIconButtons()
                        ? 'grey.light'
                        : 'transparent',
                    ...sxAction,
                }}
            >
                {isSmallScreen && !hasIconButtons() ? (
                    <Stack
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        width="100%"
                    >
                        {buttons.map((button, index) => (
                            <Button
                                key={index}
                                fullWidth
                                onClick={button.onClick}
                                disabled={button.disabled}
                                variant={button.variant}
                                color={button.color}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Stack>
                ) : (
                    buttons.map((button, index) =>
                        hasIconButtons() ? (
                            <Tooltip
                                key={index}
                                title={button?.title || button?.label}
                            >
                                <IconButton
                                    onClick={button.onClick}
                                    disabled={button.disabled}
                                    color={button.color || 'inherit'}
                                >
                                    {button.icon}
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Button
                                key={index}
                                onClick={button.onClick}
                                disabled={button.disabled}
                                variant={button.variant}
                                color={button.color}
                            >
                                {button.label}
                            </Button>
                        ),
                    )
                )}
            </DialogActions>
        );
    };

    return (
        <Dialog open={show} maxWidth={size} fullWidth={fullWidth} {...rest}>
            {modalLogo && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>{modalLogo}</Box>
            )}
            {title && (
                <>
                    <DialogTitle>{title}</DialogTitle>
                    {handleClose && (
                        <Tooltip title="Close">
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 12,
                                    top: 12,
                                }}
                            >
                                <CloseRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Divider />
                </>
            )}

            {(tabs || modalBody) && (
                <DialogContent sx={sxBody}>
                    {/* Render Tabs if the `tabs` prop is passed */}
                    {tabs ? (
                        <>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="Modal Tabs"
                            >
                                {tabs.map((tab, index) => (
                                    <Tab key={index} label={tab.label} />
                                ))}
                            </Tabs>
                            <Box sx={{ paddingTop: 2 }}>
                                {tabs[selectedTab] && (
                                    <Typography variant="body1">
                                        {tabs[selectedTab].content}
                                    </Typography>
                                )}
                            </Box>
                        </>
                    ) : (
                        modalBody // Default body content if no tabs
                    )}
                </DialogContent>
            )}

            {customFooter ? (
                <DialogActions>{customFooter}</DialogActions>
            ) : (
                renderFooterButtons()
            )}
        </Dialog>
    );
};

export default Modal;
