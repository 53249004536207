import React, { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { config } from '../../../config/config';

const SplashScreen = () => {
    const { themeIconUrl: imgurl } = config;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState(
        'https://admin.toolkit.law/storage/images/1728392473.svg',
    );

    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        if (currentUrl.endsWith('assistant-uninstall')) {
            setImageUrl(
                'https://admin.toolkit.law/storage/images/toolkitlaw-logo-assistant-square-svg.svg',
            );
        }
    }, []);

    const images = [
        `${imgurl}assistant_footer_icons/apple_safari_extension.svg`,
        `${imgurl}assistant_footer_icons/google_chrome_extension.svg`,
        `${imgurl}assistant_footer_icons/microsoft_edge_extension.svg`,
        `${imgurl}assistant_footer_icons/mozilla_firefox_extension.svg`,
        `${imgurl}assistant_footer_icons/opera_browser_extension.svg`,
        `${imgurl}assistant_footer_icons/microsoft_excel.svg`,
        `${imgurl}assistant_footer_icons/microsoft_onenote.svg`,
        `${imgurl}assistant_footer_icons/microsoft_outlook.svg`,
        `${imgurl}assistant_footer_icons/microsoft_powerpoint.svg`,
        `${imgurl}assistant_footer_icons/microsoft_word.svg`,
        `${imgurl}assistant_footer_icons/microsoft_teams.svg`,
        `${imgurl}assistant_footer_icons/google_marketplace.svg`,
        'https://admin.toolkit.law/storage/images/wearos.png',
        `${imgurl}assistant_footer_icons/adobe_acrobat.svg`,
        `${imgurl}assistant_footer_icons/zoom_workplace_app.svg`,
    ];

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f5f5f5', // Set a light background color for contrast
                position: 'fixed', // Fix position to the viewport
                top: 0,
                left: 0,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '300px',
                    mb: 2,
                    flexDirection: 'column',
                }}
                onLoad={() => setImageLoaded(true)}
            >
                <Box
                    component="img"
                    src={imageUrl}
                    alt="Logo"
                    sx={{ maxWidth: '80%', height: '150px' }}
                    onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image is loaded
                />
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5, 1fr)',
                        justifyItems: 'center', // Center the icons horizontally
                        alignItems: 'center', // Center the icons vertically
                        width: '100%',
                        mt: 2,
                        flexWrap: 'wrap', // Wrap icons if they overflow
                    }}
                >
                    {images.map((image, index) => (
                        <Box
                            key={index}
                            component="img"
                            src={image}
                            alt={`Microsoft Icon ${index}`}
                            sx={{
                                width: 26,
                                height: 26,
                                mx: 1,
                                my: 0.5,
                            }}
                            onLoad={() => setImageLoaded(true)}
                        />
                    ))}
                </Box>
                {/* Display the progress bar below the image once the image is loaded */}
                {imageLoaded && (
                    <LinearProgress
                        sx={{ width: '80%', height: '5px', mt: 2 }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default SplashScreen;
