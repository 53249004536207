import { useCallback, useEffect, useState } from 'react';

import axios from 'axios';

import { io } from 'socket.io-client';

import { getAuthData } from './auth';
import { transformResponse } from './Utils';
import { logger } from './SiteHelpers';
import { config } from '../config/config';

const { apiUrl, apiKey, chatterApiUrl, auth0RedirectUrl } = config;

const publicAxios = axios.create({
    baseURL: apiUrl,
    headers: {
        'X-API-Key': apiKey,
    },
});

export const handleLogout = async (user, logout) => {
    try {
        if (user !== null) {
            const email = user.email;
            const auth0id = user.sub;
            const authData = await getAuthData(email, auth0id);
            if (authData.userId !== null && authData.accessToken !== null) {
                const customHeaders = {
                    Authorization: `Bearer ${authData.accessToken}`,
                };

                const response = await userAxios.post(`logout`, null, {
                    headers: customHeaders,
                });
                if (response.status === 200) {
                    // Clear the entire sessionStorage
                    sessionStorage.clear();
                    logout({
                        returnTo: auth0RedirectUrl,
                    });
                    logger.log('Logout successful');
                } else {
                    logger.error(
                        'Failed to fetch user notes. Status:',
                        response.status,
                    );
                    // Handle the case when the API returns a status other than 200 (e.g., 400)
                }
            }
        }
    } catch (error) {
        // Handle error
        logger.error('Error fetching user notes:', error);
    }
};

export const userAxios = axios.create({
    baseURL: apiUrl,
});

export const processAPI = async (
    apiEndPoint,
    intialMessage,
    message,
    user,
    setShowLoader,
    setLoaderMessage,
    setSuccessMessage,
    setErrorMessage,
    checkAuthentication,
    fetchUserData,
    payload,
) => {
    try {
        if (!checkAuthentication()) {
            return;
        }
        setShowLoader(true);
        setLoaderMessage(intialMessage);
        const email = user.email;
        const auth0id = user.sub;
        const authData = await getAuthData(email, auth0id);
        if (authData.accessToken !== null) {
            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'application/json', // Specify the content type of the request body
            };

            await userAxios
                .post(`${apiEndPoint}`, payload, {
                    headers: customHeaders,
                })
                .then((response) => {
                    setShowLoader(false);
                    setSuccessMessage(message);
                    fetchUserData();
                })
                .catch((errors) => {
                    setShowLoader(false);
                    logger.log(errors.response);
                    const apiErrors = errors.response;
                    setErrorMessage('API Error: ' + JSON.stringify(apiErrors));
                    logger.error('Post request failed:', errors);
                    // Handle errors here
                });
        }
    } catch (error) {
        logger.error('Error:', error);
        setErrorMessage('Failed to clear data. Please try again.');
    }
};

export const handleSaveForm = async (
    event,
    user,
    checkAuthentication,
    setShowLoader,
    setLoaderMessage,
    index,
    form,
    moreFields,
    apiEndPoint,
    fetchUserData,
    setErrorMessage,
) => {
    event.preventDefault(); // Prevent the default form submission behavior (refreshing the page)
    try {
        if (!checkAuthentication()) {
            return;
        }
        setShowLoader(true);
        setLoaderMessage('Saving');
        const email = user.email;
        const auth0id = user.sub;
        const authData = await getAuthData(email, auth0id);
        if (authData.accessToken !== null) {
            const formId = index;
            form.user_id = authData.userId;
            //todo: Can we add in hidden field
            form.form_id = formId;
            form.data = moreFields;

            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'application/json', // Specify the content type of the request body
            };

            await userAxios
                .post(`${apiEndPoint}`, form, { headers: customHeaders })
                .then((response) => {
                    logger.log(response);
                    setShowLoader(false);
                    //commenting this message as tom needs only this
                    // setSuccessMessage('Saving: Done');
                    fetchUserData();
                })
                .catch((errors) => {
                    setShowLoader(false);
                    logger.log(errors.response.data.data);
                    const apiErrors = errors.response.data.data;
                    setErrorMessage('API Error: ' + JSON.stringify(apiErrors));
                    logger.error('Post request failed:', errors);
                    // Handle errors here
                });
        }
    } catch (error) {
        logger.error('Error:', error);
        setErrorMessage('Saving: Failed');
    }
};

export const getUserData = async (
    endpoint,
    user,
    setDbData,
    loadUserComponents,
) => {
    try {
        if (!user) {
            return; // Early return if user is null
        }

        const { email, sub: auth0id } = user;
        const authData = await getAuthData(email, auth0id);

        if (authData.userId && authData.accessToken) {
            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
            };
            const response = await userAxios.get(
                `${endpoint}?userId=${authData.userId}`,
                { headers: customHeaders },
            );

            if (response.status === 200) {
                const { data } = response; // Destructure the 'data' array from the response
                // Assuming transformResponse is defined elsewhere
                const transformedResponse = transformResponse(
                    data.data,
                    'form_id',
                );
                setDbData(transformedResponse);
                if (transformedResponse !== null) {
                    loadUserComponents(transformedResponse);
                }
            } else {
                logger.error('Missing userId or accessToken in authData.');
                // Handle the case when required properties are missing in authData
            }
        }
    } catch (error) {
        // Handle error
        logger.error('Error fetching user notes:', error);
    }
};

export const getFormattedMedia = async (
    endpoint,
    user,
    payload,
    setShowLoader,
    setLoaderMessage,
    setErrorMessage,
) => {
    try {
        if (!user) {
            return; // Early return if user is null
        }

        const { email, sub: auth0id } = user;
        const authData = await getAuthData(email, auth0id);

        if (authData.userId && authData.accessToken) {
            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'multipart/form-data',
            };

            const formData = new FormData();
            formData.append('media', payload.audio);
            formData.append('format', payload.format);

            const response = await userAxios.post(`${endpoint}`, formData, {
                headers: customHeaders,
            });

            if (response.status === 200) {
                logger.log(response);
                const { data } = response;

                if (data.data !== null) {
                    return data.data;
                }
            } else {
                logger.error('Missing userId or accessToken in authData.');
                return null;
                // Handle the case when required properties are missing in authData
            }
        }
    } catch (error) {
        // Handle error
        logger.error('Error Converting and downloading media:', error);
        setErrorMessage('Downloading: Failed');
        setShowLoader(false);
    }
};

export const getConvertedFile = async (
    endpoint,
    user,
    payload,
    setShowLoader,
    setLoaderMessage,
    setErrorMessage,
) => {
    try {
        if (!user) {
            return; // Early return if user is null
        }

        const { email, sub: auth0id } = user;
        const authData = await getAuthData(email, auth0id);

        if (authData.userId && authData.accessToken) {
            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'multipart/form-data',
            };

            const formData = new FormData();
            formData.append('file', payload.file);
            const apiEndPoint = `https://tools.toolkit.law/api/${endpoint}`;

            const response = await userAxios.post(apiEndPoint, formData, {
                headers: customHeaders,
            });

            if (response.status === 200) {
                return response.data;
            } else {
                logger.error('Missing userId or accessToken in authData.');
                return null;
            }
        }
    } catch (error) {
        // Handle error
        logger.error('Error uploading file:', error);
        setErrorMessage('Uploading: Failed');
        setShowLoader(false);
    }
};

export default publicAxios;

export const chatAxios = axios.create({
    baseURL: chatterApiUrl,
    headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json',
    },
});

/**
 * A hook that fetches data from an API endpoint using Axios.
 * @param {Object} configObject - An object containing configuration properties for the Axios fetch operation.
 * @param {import('axios').AxiosInstance} configObject.axiosInstance - The Axios instance to use for the request. If not provided, the default Axios instance will be used.
 * @param {string} configObject.method - The HTTP method to use for the request. Defaults to 'GET'.
 * @param {string} configObject.url - The URL to send the request to.
 * @param {import('axios').AxiosRequestConfig} configObject.requestConfig - Additional configuration options for the Axios request.
 * @return {Promise} The response data from the Axios fetch operation.
 * @example
 * const { response, error, loading } = useAxios({
 *     method: 'GET',
 *     url: '/api/users',
 * });
 */
export const useChatAxios = (initialConfig) => {
    const [config, setConfig] = useState(initialConfig);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    /**
     * A function that performs an asynchronous Axios fetch operation based on the provided configuration object.
     *
     * @param {Object} configObject - An object containing configuration properties for the Axios fetch operation.
     * @param {import('axios').AxiosInstance} configObject.axiosInstance - The Axios instance to use for the request. If not provided, the default Axios instance will be used.
     * @param {string} configObject.method - The HTTP method to use for the request. Defaults to 'GET'.
     * @param {string} configObject.url - The URL to send the request to.
     * @param {import('axios').AxiosRequestConfig} configObject.requestConfig - Additional configuration options for the Axios request.
     * @return {Promise} The response data from the Axios fetch operation.
     */
    const axiosFetch = useCallback(async (configObject) => {
        const controller = new AbortController();
        const {
            axiosInstance,
            method = 'get',
            url,
            requestConfig = {},
        } = configObject;

        if (!initialConfig) {
            setConfig(configObject);
        }

        setLoading(true);
        setError(null);
        setResponse(null);

        try {
            const res = await (axiosInstance || chatAxios)[
                method.toLowerCase()
            ](url, {
                ...requestConfig,
                signal: controller.signal,
            });

            if (
                res.status === 200 ||
                res.status === 201 ||
                res.data.status === 'success'
            ) {
                setResponse(res.data.data);
            } else {
                const errorMessage =
                    res?.data?.data?.message ||
                    res?.data?.error ||
                    'Unknown error';
                throw new Error(errorMessage);
            }

            return res?.data;
        } catch (err) {
            const errorObj = {
                message: `${err?.response?.data?.message || err.message}`,
                status: 'Error',
            };
            setError(errorObj);
            throw new Error(errorObj.message);
            // return errorObj;
        } finally {
            setLoading(false);
        }

        // return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialConfig) {
            setConfig(initialConfig);
            axiosFetch(initialConfig);
        }
    }, [initialConfig, axiosFetch]);

    const refresh = useCallback(() => {
        if (config) {
            axiosFetch(config);
        }
    }, [config, axiosFetch]);

    return { response, setResponse, error, loading, axiosFetch, refresh };
};

export const useSocket = (query) => {
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState(null); // New state to track connection errors

    useEffect(() => {
        if (query.userId) {
            try {
                // Create a new WebSocket connection when the component mounts
                const newSocket = io(chatterApiUrl, {
                    query: query,
                    transports: ['websocket', 'polling'],
                });

                // Set the socket state to the new WebSocket connection
                setSocket(newSocket);

                // Clean up function to close the WebSocket connection when the component unmounts
                return () => {
                    newSocket.disconnect();
                };
            } catch (err) {
                logger.error('Error during WebSocket setup:', err);
                setError(err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.userId]);

    // Function to emit events to the server
    const emitEvent = (event, data) => {
        if (socket && socket.connected) {
            socket.emit(event, data);
        } else {
            logger.warn('Socket not connected, unable to emit event:', event);
        }
    };

    // Function to listen for events from the server
    const onEvent = (event, callback) => {
        if (socket) {
            socket.on(event, callback);
        } else {
            logger.warn(
                'Socket not connected, unable to listen for event:',
                event,
            );
        }
    };

    return { socket, emitEvent, onEvent, error };
};
