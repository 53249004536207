import { createContext, useState } from 'react';

export const LoaderContext = createContext({
    showLoader: false,
    setShowLoader: () => {},
    loaderMessage: 'Loading',
    setLoaderMessage: () => {},
    startLoading: () => {},
    stopLoading: () => {},
});

/**
 * Provides a context for managing the loader state and props.
 *
 * The loader context provider allows us to manage the state of the loader
 * (whether it is visible or not) and props (message). This makes it easier
 * to manage the state of the loader across different components.
 *
 * @param children - The components that should be wrapped by the loader
 *                    context provider.
 * @returns A context provider that wraps the given children with the loader
 *          context.
 */
export const LoaderProvider = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState('Loading');

    /**
     * Shows the loader with the given message.
     *
     * @param {string} [message] - The message to display on the loader.
     */
    const startLoading = (message) => {
        if (typeof message !== 'string') {
            throw new Error('Loader message must be a string.');
        }

        setLoaderMessage(message);
        setShowLoader(true);
    };

    /**
     * Hides the loader.
     *
     * Call this function when the task that the loader was indicating is complete.
     */
    const stopLoading = () => {
        setShowLoader(false);
    };

    return (
        <LoaderContext.Provider
            value={{
                showLoader,
                setShowLoader,
                loaderMessage,
                setLoaderMessage,
                startLoading,
                stopLoading,
            }}
        >
            {children}
        </LoaderContext.Provider>
    );
};
