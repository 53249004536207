import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Button, Stack, Link } from '@mui/material';
import publicAxios from '../utils/api';

const ToolkitUninstalled = () => {
    const [favIcon, setFavIcon] = useState();

    const getSiteSetting = async () => {
        const { data } = await publicAxios.get(`public-settings`);
        const siteSetting = data.data.siteSetting;

        setFavIcon(
            data.data.url + '/images/header/' + siteSetting.public_fav_icon,
        );
    };

    useEffect(() => {
        getSiteSetting();
    }, []);

    const buttons = [
        {
            label: 'Provide Feedback',
            href: 'https://www.toolkit.law/about?accordion=contact-us',
        },
        {
            label: 'Share Toolkit.law',
            href: 'https://www.toolkit.law/about?accordion=share-us',
        },
        {
            label: 'Visit Toolkit.law',
            href: 'https://toolkit.law',
        },
        {
            label: 'Reinstalled Toolkit.law Assistant ',
            href: 'https://toolkit.law/tools/appstore',
        },
    ];

    return (
        <>
            <Helmet>
                <title>Toolkit.law Uninstalled</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                {favIcon && (
                    <link
                        rel="icon"
                        type="image/png"
                        href={favIcon}
                        sizes="16x16"
                    />
                )}
            </Helmet>

            <Box
                sx={{
                    fontFamily: 'Arial, sans-serif !important',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: '#f9f9f9',
                    color: '#333',
                    px: 2,
                    height: '100vh',
                    width: '100%',
                    position: 'relative',
                    backgroundImage: 'url(/assets/images/background.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: 'Arial, sans-serif !important',
                        fontSize: '2.5em',
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        mb: 2,
                        mt: 0,
                    }}
                >
                    Toolkit.law Assistant Uninstalled
                </Typography>

                <Stack
                    spacing={2}
                    sx={{
                        width: '100%',
                        maxWidth: '500px',
                        mx: 'auto',
                    }}
                >
                    {buttons
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((button, index) => (
                            <Button
                                key={index}
                                href={button.href}
                                variant="contained"
                                sx={{
                                    padding: '20px 30px',
                                    color: 'black',
                                    fontSize: '1.5rem',
                                    '&:hover': {
                                        color: 'black',
                                    },
                                }}
                            >
                                {button.label}
                            </Button>
                        ))}
                </Stack>

                <Box
                    component={Link}
                    href="https://www.toolkit.law"
                    rel="noopener noreferrer"
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                    }}
                >
                    <Box
                        component={'img'}
                        src={
                            'https://admin.toolkit.law/storage/banner_images/home_section_ads/adImage1727366136_0_desktop.svg'
                        }
                        sx={{
                            marginTop: '30px',
                            maxWidth: '100%',
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ToolkitUninstalled;
