import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from '../config/config';

const Auth0ProviderWithHistory = ({ children }) => {
    const { auth0Domain, auth0ClientId, auth0RedirectUrl } = config;

    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: auth0RedirectUrl,
            }}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
