import React, { useContext, useEffect } from 'react';

import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import Modal from '../modal/Modal';
import { useCoverSheetModal } from '../../../context/CoverSheetContext';
import { UserSettingsContext } from '../../../context/UserSettingsContext';
import { getAuthData } from '../../../utils/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { userAxios } from '../../../utils/api';
import { logger } from '../../../utils/SiteHelpers';

const CoverSheetModal = () => {
    const {
        coverSheetOptions,
        setCoverSheetOptions,
        modalState,
        closeCoverSheetModal,
    } = useCoverSheetModal();
    const { userSettings } = useContext(UserSettingsContext);
    const { show, onSubmit } = modalState;
    const { user } = useAuth0();

    const handleSubmitModal = () => {
        closeCoverSheetModal();
        updateCoverSheet();
        onSubmit(coverSheetOptions);
    };

    const updateCoverSheet = async () => {
        try {
            const { email, sub: auth0id } = user;
            const authData = await getAuthData(email, auth0id);

            if (!authData.accessToken || !authData.userId) {
                logger.error('Missing accessToken or userId in authData.');
                return;
            }

            const apiData = {
                user_id: authData.userId,
                data: coverSheetOptions,
            };
            const customHeaders = {
                Authorization: `Bearer ${authData.accessToken}`,
                'Content-Type': 'application/json',
            };
            await userAxios
                .post(`coversheet/create-or-update`, apiData, {
                    headers: customHeaders,
                })
                .then(() => {
                    logger.info('Cover sheet updated successfully');
                })
                .catch((errors) => {
                    const apiErrors = errors.response.data.data;
                    logger.error('Post request failed:', errors);
                })
                .finally(() => {});
        } catch (error) {
            logger.error('Error updating user settings:', error);
        }
    };

    useEffect(() => {
        if (userSettings?.cover_sheet?.length > 0) {
            setCoverSheetOptions(userSettings.cover_sheet);
        }
    }, [userSettings]);

    if (!show) return null; // Prevent rendering if modal is not visible
    return (
        <Modal
            show={show}
            handleClose={closeCoverSheetModal}
            size="xs"
            title="Coversheet Options"
            modalBody={
                <Box>
                    <Typography fontWeight="bold" variant="body2">
                        Include Coversheet
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={coverSheetOptions.includeCoverSheet}
                            onChange={(e) =>
                                setCoverSheetOptions((prev) => ({
                                    ...prev,
                                    includeCoverSheet:
                                        e.target.value === 'true',
                                }))
                            }
                        >
                            <MenuItem value="false">No</MenuItem>
                            <MenuItem value="true">Yes</MenuItem>
                        </Select>
                    </FormControl>

                    {coverSheetOptions.includeCoverSheet && (
                        <>
                            <Box sx={{ mt: 2 }}>
                                <Typography fontWeight="bold" variant="body2">
                                    Line 1
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={coverSheetOptions.line1}
                                    onChange={(e) =>
                                        setCoverSheetOptions((prev) => ({
                                            ...prev,
                                            line1: e.target.value,
                                        }))
                                    }
                                    placeholder="Enter Text, such as Client Name"
                                    variant="outlined"
                                />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography fontWeight="bold" variant="body2">
                                    Line 2
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={coverSheetOptions.line2}
                                    onChange={(e) =>
                                        setCoverSheetOptions((prev) => ({
                                            ...prev,
                                            line2: e.target.value,
                                        }))
                                    }
                                    placeholder="Enter Text, such as Case Name"
                                    variant="outlined"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            }
            footerButtons={[
                {
                    label: 'Submit',
                    onClick: handleSubmitModal,
                },
                {
                    label: 'Cancel',
                    onClick: closeCoverSheetModal,
                    variant: 'dark',
                },
            ]}
        />
    );
};

export default CoverSheetModal;
